import { loginDiscordAPI, reloadUser } from "./redux/reducers/user";
import { post } from "./request";

const AUTH_ENDPOINT = "https://discord.com/api/oauth2/authorize"

const RESPONSE_TYPE = "code"
const SCOPES = "identify"

function getQuerystring(key) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == key) {
            return pair[1];
        }
    }
}

export function getDiscordAuthLink() {
    return AUTH_ENDPOINT + "?client_id=" + window.env.DISCORD_CLIENT_ID + "&redirect_uri=" + window.env.DISCORD_REDIRECT_URI + "&response_type=" + RESPONSE_TYPE + "&scope=" + SCOPES
}


export async function loginDiscord(dispatch){
    let code = getQuerystring("code")
    if (code != null) {
        await dispatch(loginDiscordAPI(code))
    }

}