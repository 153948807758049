import { configureStore } from "@reduxjs/toolkit";
import { guildsReducer } from "./reducers/guilds";
import { musicReducer } from "./reducers/music";
import { spotifyReducer } from "./reducers/spotify";
import { statsReducer } from "./reducers/stats";
import { userReducer } from "./reducers/user";
import { voiceReducer } from "./reducers/voice";



const store = configureStore({
    reducer:{
        user: userReducer,
        guilds: guildsReducer,
        voice: voiceReducer,
        music: musicReducer,
        spotify: spotifyReducer,
        stats: statsReducer
    }
})

export default store;