
import { useEffect } from "react"
import { Button } from "react-bootstrap"
import { Container, Navbar, Dropdown } from "react-bootstrap"
import { BarChart, BoxArrowInRight, BoxArrowRight, Person } from "react-bootstrap-icons"
import { useDispatch, useSelector } from "react-redux"
import { LinkContainer } from "react-router-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { loginDiscord } from "./utils/discord"
import { reloadGuilds, setActiveGuild } from "./utils/redux/reducers/guilds"
import { loginSpotify, reloadSpotify } from "./utils/redux/reducers/spotify"
import { reloadStats } from "./utils/redux/reducers/stats"
import { logoutUser, reloadUser } from "./utils/redux/reducers/user"

export default function Page(props) {

    const state = useSelector(state => state)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        if (location.pathname == "/profile") {
            dispatch(loginSpotify()).then(() => {
                dispatch(reloadUser()).then(() => {
                    dispatch(reloadGuilds())
                    dispatch(reloadSpotify())
                })

            })
        } else {
            loginDiscord(dispatch).then(() => {
                dispatch(reloadUser()).then(() => {
                    dispatch(reloadGuilds())
                    dispatch(reloadSpotify())
                })
            })
        }


    }, [])

    useEffect(() => {
        console.log("STATE-----------------------------------------------")
        console.log(state)
        console.log("STATE_END-----------------------------------------------")
    }, [state])

    useEffect(() => {
        if (state.user.user == null && state.user.isChecked) {
            navigate("/login")
        }
    }, [state.user])

    return (
        <div>
            <Navbar variant="dark" bg="dark">
                <Container>
                    <div>
                        <LinkContainer to="/"><Navbar.Brand >MAOS-Bot</Navbar.Brand></LinkContainer>
                    </div>
                    <div>
                        {state.user.user != null && state.user.isChecked &&
                            <div className="d-flex align-items-center">
                                {state.guilds.active != null && state.guilds.guilds.length == 1 &&
                                    <Navbar.Text className="me-2">{state.guilds.active.name}</Navbar.Text>
                                }
                                {state.guilds.active != null && state.guilds.guilds.length > 1 &&
                                        <Dropdown className="m-2">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {state.guilds.active.name}
                                        </Dropdown.Toggle>
                                  
                                        <Dropdown.Menu>
                                            {state.guilds.guilds.map(guild => <Dropdown.Item onClick={() => dispatch(setActiveGuild(guild))} >{guild.name}</Dropdown.Item>)}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                }
                                <LinkContainer to="/profile"><Button className="m-2" variant="secondary"><Person size={20}></Person></Button></LinkContainer>
                                <LinkContainer to="/stats"><Button className="m-2 d-none d-sm-block" variant="secondary"><BarChart size={20}></BarChart></Button></LinkContainer>
                                <Button className="m-2 me-0" variant="secondary" onClick={() => dispatch(logoutUser())}><BoxArrowRight size={20}/></Button>
                            </div>}
                        {state.user.user == null && state.user.isChecked &&
                            <LinkContainer to="/login"><Button className="m-2" variant="primary"><BoxArrowInRight size={20}></BoxArrowInRight></Button></LinkContainer>}
                    </div>
                </Container>
            </Navbar>
            <Container className="mt-3 mb-4">
                <h1 className="mb-3">{props.title}</h1>
                <div>
                    {props.children}
                </div>

            </Container>
        </div>
    )
}