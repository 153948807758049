import { createAsyncThunk, createReducer, createSlice } from "@reduxjs/toolkit";

import { get, post } from "../../request";
import { getFromStorage, removeFromStorage, saveToStorage } from "../../storage";

const INITIAL_STATE = {
    isChecked: false,
    user: null
}

export const reloadUser = createAsyncThunk(
    "user/reloadUser",
    async (empty, thunkAPI) => {

        const response = await get("/user/me", true)

        if (response.status == 200) {
            return thunkAPI.fulfillWithValue(response.data)
        }
        return thunkAPI.rejectWithValue(null);
    }


)


export const logoutUser = createAsyncThunk(
    "user/logout",
    async (empty, thunkAPI) => {
        removeFromStorage("token")
        removeFromStorage("refreshToken")
        return thunkAPI.fulfillWithValue(null)
    }
)

export const loginDiscordAPI = createAsyncThunk(
    "user/loginDiscord",
    async (discordCode, thunkAPI) => {
        const response = await post("/login", { "discordCode": discordCode })
        if (response.status == 200) {
            window.location.search = ""
            saveToStorage("token", response.data.token)
            saveToStorage("refreshToken", response.data.refreshToken)
        }
        return thunkAPI.rejectWithValue(null);

    }
)

const userSlice = createSlice({
    name: "user",
    initialState: INITIAL_STATE,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(reloadUser.fulfilled, (state, action) => {
            state.user = action.payload;
            state.isChecked = true;
        })
        builder.addCase(reloadUser.rejected, (state, action) => {
            state.isChecked = true;
        })
        builder.addCase(logoutUser.fulfilled, (state, action) => {
            state.isChecked = true;
            state.user = null;
        })
    }
})



export const { } = userSlice.actions;
export const userReducer = userSlice.reducer;
