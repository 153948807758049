import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { get } from "../../request";

import { getFromStorage } from "../../storage";
import { subscribe } from "../../websocket";
import { registerMusicState, unregisterMusicState } from "./music";
import { reloadStats } from "./stats";
import { registerVoiceState, unregisterVoiceState } from "./voice";

const INITIAL_STATE = {
    guilds: [],
    active: null
}



export const reloadGuilds = createAsyncThunk(
    "guilds/reloadGuilds",
    async (empty, thunkAPI) => {
        if (getFromStorage("token") != null) {
            const response = await get("/guild", true)

            if (response.status == 200) {
                if (response.data.length >= 1) {


                    await thunkAPI.dispatch(setActiveGuild(response.data[0]))
                }

                return thunkAPI.fulfillWithValue(response.data)
            }
        }

        return thunkAPI.rejectWithValue(null);
    }
)

export const setActiveGuild = createAsyncThunk(
    "guilds/setActiveGuild",
    async (guild, thunkAPI) => {

        await thunkAPI.dispatch(unregisterVoiceState())
        await thunkAPI.dispatch(unregisterMusicState())

        

        thunkAPI.dispatch(registerVoiceState(guild.guildId))
        thunkAPI.dispatch(registerMusicState(guild.guildId))
        await thunkAPI.dispatch(guildsSlice.actions.setActive(guild));

        thunkAPI.dispatch(reloadStats())

    }
)

const guildsSlice = createSlice({
    name: "guilds",
    initialState: INITIAL_STATE,
    reducers: {
        setActive(state, action) {
            state.active = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(reloadGuilds.fulfilled, (state, action) => {
            state.guilds = action.payload;
        })
    }
})



export const { } = guildsSlice.actions;
export const guildsReducer = guildsSlice.reducer;
