import { ListGroupItem, Button, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { shameMember } from "../utils/redux/reducers/voice";


export default function Shame(props) {

    const voice = useSelector(state => state.voice)
    const dispatch = useDispatch();


    function resolveVoiceChannel(id) {
        return voice.data.channels.filter(item => item.id == id)[0].name;
    }


    const voiceUsersFormatted = voice.data != null ? voice.data.members.map(member => {
        let voiceChannel = resolveVoiceChannel(member.voiceChannelId)
        return (
            <ListGroupItem key={member.id} className="d-flex justify-content-between align-items-center">
                <div><b>{member.name}</b> ({voiceChannel})</div>
                <div>
                    {member.shameable && <Button variant="danger" onClick={() => dispatch(shameMember(member.id)).then((res) => {console.log(res);props.setAlert({"show":true, "variant":"primary", "message":res.payload})})}>Shame</Button>}
                </div>
            </ListGroupItem>
        )
    }) : ""

    return (
        <div>
            {voiceUsersFormatted.length != 0 &&
                <ListGroup className="mb-5">
                    {voiceUsersFormatted}
                </ListGroup>
            }
            {voiceUsersFormatted.length == 0 &&
                <div>No User present on Server!</div>
            }
        </div>
    );
}