import { Stomp } from "@stomp/stompjs";
import { getFromStorage } from "./storage";

const WS_URL = window.env.WS_URL
let subs = [];
let clientStarted = false;
let client;


function startClient(){
    if(!clientStarted){
        client = Stomp.client(WS_URL)
        client.connectHeaders.token = getFromStorage("token")
        
        client.onConnect = (frame) => {
            processSubs()
        }
        client.activate()
    }

    clientStarted = true
}



function processSubs() {
    if (client.connected) {
        subs.forEach(sub => {
            sub = client.subscribe("/topic" + sub.path, sub.callback, {id: sub.id})
        })

        subs = []
    }

}

export function subscribe(path, callback, id) {
    startClient()
    subs.push({ path: path, callback: callback , id: id})
    processSubs()
}

export function unsubscribe(subscriptionId) {
    client.unsubscribe(subscriptionId)
}