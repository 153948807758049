import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Tab, Tabs, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select, { components, createFilter } from "react-select";
import AsyncSelect from 'react-select/async';
import { requestSpotifyData } from "../utils/redux/reducers/spotify";



export default function MusicModal(props) {

    const state = useSelector(state => state.spotify)
    const dispatch = useDispatch()

    const [val, setVal] = useState("");
    const [playlists, setPlaylists] = useState([]);
    const [searchType, setSearchType] = useState("playlist")

    useEffect(() => {
        if (state.isChecked) {
            dispatch(requestSpotifyData({url:"me/playlists?limit=50"})).then(res => {
                console.log(res)
                setPlaylists(res.payload.data.items)
            })
        }
    }, [state])

    const mappedPlaylists = playlists.map(item => ({ value: item.external_urls.spotify, label: <div><b>{item.name}</b> ({item.owner.display_name})</div>, labelClean: item.name+" "+item.owner.display_name }))

    const ownPlaylistFilter = createFilter({
        stringify: option =>`${option.data.labelClean}`
    })

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            dispatch(requestSpotifyData({url:"search?type=" + searchType + "&limit=50&q=" + inputValue})).then(res => {
                switch (searchType) {
                    case "playlist":
                        resolve(res.payload.data.playlists.items.map(item => ({ label: <div><div><b>{item.name}</b> ({item.owner.display_name})</div><div>{item.description}</div></div>, value: item.external_urls.spotify })))
                        break;
                    case "track":
                        resolve(res.payload.data.tracks.items.map(item => ({ label: <div><div><b>{item.name}</b> ({item.artists.map(elem => elem.name).join(", ")})</div></div>, value: item.external_urls.spotify })))
                        break;
                    case "album":
                        resolve(res.payload.data.albums.items.map(item => ({ label: <div><div><b>{item.name}</b> ({item.artists.map(elem => elem.name).join(", ")})</div></div>, value: item.external_urls.spotify })))
                        break;
                    case "artist":
                        resolve(res.payload.data.artists.items.map(item => ({ label: <div><div><b>{item.name}</b></div></div>, value: item.external_urls.spotify })))
                        break;
                }

            })
        });

    return (
        <Modal centered show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Play Music</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Tabs defaultActiveKey="url" className="mb-3">

                    <Tab title="URL" eventKey="url">
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Spotify URL:</Form.Label>
                                <Form.Control type="text" placeholder="Spotify URL" onChange={event => {
                                    setVal(event.target.value)
                                }} />
                            </Form.Group>
                        </Form>
                    </Tab>
                    {state.isChecked &&
                        <Tab title="My Playlists" eventKey="playlists">
                            <Form>
                                <Form.Group className="mb-3">
                                    <Select filterOption={ownPlaylistFilter} options={mappedPlaylists} onChange={value => setVal(value.value)}>
                                    </Select>
                                </Form.Group>
                            </Form>
                        </Tab>
                    }
                    {state.isChecked &&
                        <Tab title="Search" eventKey="search">
                            <Form>
                                <Form.Group className="mb-3">
                                    <Select defaultValue={{ value: "playlist", label: "Playlist" }} options={[{ label: "Playlist", value: "playlist" }, { label: "Track", value: "track" }, { label: "Album", value: "album" }, { label: "Artist", value: "artist" }]} onChange={value => setSearchType(value.value)}>
                                    </Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <AsyncSelect loadOptions={promiseOptions} onChange={value => setVal(value.value)}>
                                    </AsyncSelect>
                                </Form.Group>
                            </Form>
                        </Tab>
                    }
                </Tabs>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>Close</Button>
                <Button variant="primary" onClick={() => props.handleSend(val)}>Send</Button>
            </Modal.Footer>
        </Modal>
    )
}