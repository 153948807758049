import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDiscordAuthLink, loginDiscord } from "../utils/discord";

export default function Login(props) {

    const state = useSelector(state => state)
    const navigate = useNavigate()

    useEffect(() => {
        if (state.user.user != null) {
            navigate("/")
        }

    }, [state.user])

    function discordLogin(e){
        e.preventDefault()
        window.location.href = getDiscordAuthLink()
    }

    return (
        <div>


                    <Col xs="4" md="3" className="d-flex align-items-end justify-content-end">
                        <Button className="mb-3 w-100" variant="primary" type="submit" onClick={discordLogin}>
                            Login
                        </Button>
                    </Col>

        </div>
    )
}