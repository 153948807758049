import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "../../request";
import { getFromStorage } from "../../storage";


const INITIAL_STATE = {
    playback:{}
}


export const reloadStats = createAsyncThunk(
    "guilds/reloadStats",
    async (empty, thunkAPI) => {
        if (getFromStorage("token") != null) {
            const response = await get("/stats/playback", true, thunkAPI.getState().guilds.active.guildId)

            console.log(response)
            if (response.status == 200) {
                return thunkAPI.fulfillWithValue(response.data)
            }
        }

        return thunkAPI.rejectWithValue(null);
    }
)

const statsSlice = createSlice({
    name: "stats",
    initialState: INITIAL_STATE,
    reducers: {

    },
    
    extraReducers: (builder) => {
        builder.addCase(reloadStats.fulfilled, (state, action) => {
            state.playback = action.payload;
        })
    }
})



export const { } = statsSlice.actions;
export const statsReducer = statsSlice.reducer;