

export function saveToStorage(key, value){
    localStorage.setItem(key, value)
}

export function getFromStorage(key){
    return localStorage.getItem(key)
}

export function removeFromStorage(key){
    localStorage.removeItem(key)
}