import { Collapse, Tab, Tabs } from "react-bootstrap"
import { useEffect, useState } from "react"
import { Alert, ListGroupItem } from "react-bootstrap"
import Shame from "../components/Shame"
import MusicPlayer from "../components/MusicPlayer"
import QueueHandler from "../components/QueueHandler"


export default function Home(props) {
    const [alert, setAlert] = useState({
        show: false,
        variant: "primary",
        message: ""
    })


    return (
        <div>
            <Collapse in={alert.show}>
                <div>
                    <Alert variant={alert.variant} onClose={() => setAlert({ show: false, message: "" })} dismissible>
                        {alert.message}
                    </Alert>
                </div>
            </Collapse>

            <Tabs defaultActiveKey="shame" className="mb-3">
                <Tab eventKey="shame" title="Shame">
                    <Shame setAlert={setAlert}></Shame>
                </Tab>
                <Tab eventKey="music" title="Music">
                    <MusicPlayer></MusicPlayer>
                </Tab>
                <Tab eventKey="queue" title="Queue">
                    <QueueHandler></QueueHandler>
                </Tab>
            </Tabs>

        </div>
    )
}