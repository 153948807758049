import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { post } from "../../request"
import { subscribe, unsubscribe } from "../../websocket"

const INITIAL_STATE = {
    data:{
        currentTrack:null
    },
    subscription: null
}

export const reorderQueue = createAsyncThunk(
    "music/reorderQueue",
    async(data, thunkAPI)=>{
        return await(await post("/music/reorderQueue", data, true, thunkAPI.getState().guilds.active.guildId)).data.msg
    }
)


export const removeFromQueue = createAsyncThunk(
    "music/removeFromQueue",
    async(index, thunkAPI)=>{
        return await (await post("/music/removeFromQueue", { index: index }, true, thunkAPI.getState().guilds.active.guildId)).data.msg
    }
)

export const playNowFromQueue = createAsyncThunk(
    "music/playNowFromQueue",
    async(index, thunkAPI)=>{
        return await (await post("/music/playNowFromQueue", { index: index }, true, thunkAPI.getState().guilds.active.guildId)).data.msg
    }
)

export const shuffleQueue = createAsyncThunk(
    "music/shuffleQueue",
    async(empty, thunkAPI)=>{
        return await (await post("/music/shuffle", {}, true, thunkAPI.getState().guilds.active.guildId)).data.msg
    }
)

export const stop = createAsyncThunk(
    "music/togglePlayback",
    async(empty, thunkAPI)=>{
        return await (await post("/music/stop", {}, true, thunkAPI.getState().guilds.active.guildId)).data.msg
    }
)

export const skip = createAsyncThunk(
    "music/togglePlayback",
    async(empty, thunkAPI)=>{
        return await (await post("/music/skip", {}, true, thunkAPI.getState().guilds.active.guildId)).data.msg
    }
)

export const togglePlayback = createAsyncThunk(
    "music/togglePlayback",
    async(empty, thunkAPI)=>{
        return await (await post("/music/togglePlayback", {}, true, thunkAPI.getState().guilds.active.guildId)).data.msg
    }
)

export const registerMusicState = createAsyncThunk(
    "music/registerMusicState",
    async (guildId, thunkAPI) => {
        const subscription = subscribe("/music/"+guildId, (message) => {
            thunkAPI.dispatch(musicSlice.actions.setMusicState(JSON.parse(message.body)))
            
        }, "musicState")

        return thunkAPI.fulfillWithValue("musicState")
    }
)

export const unregisterMusicState = createAsyncThunk(
    "music/unregisterMusicState",
    async (empty, thunkAPI) => {
        unsubscribe(thunkAPI.getState().music.subscription)
        thunkAPI.dispatch(musicSlice.actions.setMusicState({currentTrack:null}))
        thunkAPI.dispatch(musicSlice.actions.setSubscription(null))
    }
)


const musicSlice = createSlice({
    name: "music",
    initialState: INITIAL_STATE,
    reducers: {
        setMusicState(state, action){
            state.data = action.payload
        },
        setSubscription(state, action){
            state.subscription = action.payload
        }
    },
    extraReducers:(builder) => {
        builder.addCase(registerMusicState.fulfilled, (state, action) => {
            state.subscription = action.payload;
        })
    }
})



export const {setMusicState } = musicSlice.actions;
export const musicReducer = musicSlice.reducer;
