import { createAsyncThunk, createReducer, createSlice } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import { get, post } from "../../request";
import { getFromStorage, removeFromStorage, saveToStorage } from "../../storage";

const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
const API_ENDPOINT = "https://api.spotify.com/v1/"
const RESPONSE_TYPE = "code"
const SCOPES = "user-follow-read,playlist-read-collaborative"

const INITIAL_STATE = {
    isChecked: false,
    name: "",
    token: null
}

function getQuerystring(key) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == key) {
            return pair[1];
        }
    }
}

export function getSpotifyAuthLink() {
    return AUTH_ENDPOINT + "?client_id=" + window.env.SPOTIFY_CLIENT_ID + "&redirect_uri=" + window.env.SPOTIFY_REDIRECT_URI + "&response_type=" + RESPONSE_TYPE + "&scope=" + SCOPES
}

export const playSpotifyURL = createAsyncThunk(
    "spotify/playSpotify",
    async (url, thunkAPI) => {
        return post("/music/playSpotify", { url: url}, true, thunkAPI.getState().guilds.active.guildId)
    }
)

export const requestSpotifyData = createAsyncThunk(
    "spotify/requestData",
    async (req, thunkAPI) => {
        let options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
        }

        options.headers.Authorization = "Bearer " + thunkAPI.getState().spotify.token
        
    
        const response = await fetch(API_ENDPOINT + req.url, options)
    
        if (response.status == 401) {
            // Invalid auth => Try Refresh
            await thunkAPI.dispatch(reloadSpotify())
    
            const response = await fetch(API_ENDPOINT + req.url, options)
    
        }
    
        return { status: response.status, data: await response.json() }
    }
)

export const loginSpotify = createAsyncThunk(
    "spotify/login",
    async (empty, thunkAPI) => {
        let code = getQuerystring("code")
        if (code != null) {
            post("/spotify/token", { code: code }, true).then(res => {

                if (res.status == 200) {
                    window.location.search = ""
                    thunkAPI.dispatch(reloadSpotify())
                }
            })
        }

    }
)

export const logoutSpotify = createAsyncThunk(
    "spotify/logout",
    async (empty, thunkAPI) => {

        await post("/spotify/logout", {}, true).then(res => {

            if (res.status == 200) {
                thunkAPI.dispatch(reloadSpotify())
            }
        })


    }
)

export const reloadSpotify = createAsyncThunk(
    "spotify/reload",
    async (empty, thunkAPI) => {
        const res = await get("/spotify/token", true);
        console.log(res)
        if (res.status == 200) {
            thunkAPI.dispatch(spotifySlice.actions.setData({
                token: res.data.token,
                isChecked: true,
                name: "TEST"
            }))
        }else{
            thunkAPI.dispatch(spotifySlice.actions.setData({
                token: null,
                isChecked: false,
                name: ""
            }))
        }
    }
)

const spotifySlice = createSlice({
    name: "spotify",
    initialState: INITIAL_STATE,
    reducers: {
        setData(state, action) {
            state.token = action.payload.token
            state.isChecked = action.payload.isChecked
            state.name = action.payload.name
        }
    }
})



export const { } = spotifySlice.actions;
export const spotifyReducer = spotifySlice.reducer;
