import moment from "moment";


export function convertUTCToLocal(timestamp){
    var utcDate = timestamp+"Z";  // ISO-8601 formatted date returned from server
    var localDate = new Date(utcDate);

    return localDate
}

export function formatDate(date){
    return moment(date).format("DD.MM.YYYY")
}

export function formatDateTime(date){
    return moment(date).format("HH:mm - DD.MM.YYYY")
}
