import { TimeSeriesScale } from "chart.js";
import 'chartjs-adapter-moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
} from 'chart.js';


import moment from "moment";
import { getElementAtEvent, Line } from "react-chartjs-2";
import { Button, ListGroup, ListGroupItem } from "react-bootstrap";
import { Forward } from "react-bootstrap-icons";
import { useRef, useState } from "react";
import { convertUTCToLocal, formatDateTime } from "../../utils/timezone";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    TimeSeriesScale,
    PointElement,
    LineElement
);

export default function EventsChart(props) {

    const [minDateSub, setMinDateSub] = useState(0)
    const [selectedDate, setSelectedDate] = useState(null)
    const chartRef = useRef();

    const onDataClick = (event) => {
        let element = getElementAtEvent(chartRef.current, event)[0].element
        setSelectedDate(element.$context.raw.x)
    }

    const listItems = selectedDate?props.getEntriesFromDate(selectedDate).map(entry => <ListGroupItem><div><b>{entry.video.title}</b> ({formatDateTime(convertUTCToLocal(entry.createDate))})</div><div>Queued by: {entry.guildMember.name}</div></ListGroupItem>):<div></div>

    return <div>
        <h2>{props.title}</h2>
        <Line className="mb-3" ref={chartRef} onClick={onDataClick} data={props.data} options={{

            scales: {
                y: {
                    ticks: {
                        precision: 0,
                        beginAtZero: true
                    }
                },
                x: {
                    type: 'timeseries',
                    min: moment().startOf("day").subtract(30 + minDateSub, "days").format("DD.MM.YYYY"),
                    max: moment().startOf("day").subtract(minDateSub, "days").format("DD.MM.YYYY"),
                    time: {
                        parser: "DD.MM.YYYY",
                        unit: "day",
                        displayFormats: {
                            day: "DD.MM.YYYY"
                        },
                        tooltipFormat: "DD.MM.YYYY"
                    }

                }
            }

        }}></Line>
        <div className="d-flex justify-content-between mb-4"><Button onClick={() => setMinDateSub(minDateSub + 30)}>Back</Button><Button onClick={() => { minDateSub - 30 > 0 ? setMinDateSub(minDateSub - 30) : setMinDateSub(0) }}>Forward</Button></div>

        <ListGroup>
            {listItems}
        </ListGroup>

    </div>
}