import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card } from "react-bootstrap";
import { PlayCircleFill, XCircleFill } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { playNowFromQueue, removeFromQueue, reorderQueue, setMusicState, shuffleQueue } from "../utils/redux/reducers/music";
import { Shuffle } from "react-bootstrap-icons";
import { post } from "../utils/request";
import { Button } from "react-bootstrap";

function QueueItem({ data, index, remove, play }) {
    return (
        <Draggable draggableId={data.id + "_" + index} index={index}>
            {provided => (
                <div
                    className="mb-2"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Card>
                        <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="me-3">
                                <b>
                                    {data.title}
                                </b>
                                <br></br>
                                Queued by: {data.user.username}
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <PlayCircleFill size={35} onClick={play} className="me-2"></PlayCircleFill>
                                <XCircleFill size={35} onClick={remove}></XCircleFill>
                            </div>
                        </Card.Body>
                    </Card>

                </div>
            )}
        </Draggable>
    )
}


export default function QueueHandler(props) {
    const state = useSelector(state => state.music.data)
    const dispatch = useDispatch()

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        let localUpdate = {...state};

        localUpdate.queue = reorderTracks(localUpdate.queue, result.source.index, result.destination.index)

        dispatch(setMusicState(localUpdate))
        dispatch(reorderQueue({ fromIndex: result.source.index, toIndex: result.destination.index }))

    }

    function removeTrack(index) {
        dispatch(removeFromQueue(index))
    }

    function playTrackNow(index) {
        dispatch(playNowFromQueue(index))
    }

    function shuffleNow(){
        dispatch(shuffleQueue())
    }

    function reorderTracks(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    const mapQueueEntries = state && state.queue ?state.queue.map((entry, index) => {
        return <QueueItem play={() => playTrackNow(index)} remove={() => removeTrack(index)} key={index} data={entry} index={index}></QueueItem>
    }):""

    return (
        <div>
            <Button onClick={shuffleNow} className="mb-3 d-flex align-items-center">Shuffle<Shuffle   size={20} className="ms-2"></Shuffle></Button>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="queue">
                    {provided =>
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {mapQueueEntries}
                            {provided.placeholder}
                        </div>
                    }
                </Droppable>
            </DragDropContext>
        </div>
    )
}