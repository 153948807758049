
import { useEffect, useState } from "react";
import { Button, Collapse, Image, ProgressBar, Spinner } from "react-bootstrap";
import { PauseCircleFill, PlayCircleFill, PlusCircleFill, SkipBackwardCircleFill, SkipForwardCircleFill, StopCircleFill, Shuffle } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { registerMusicState, skip, stop, togglePlayback } from "../utils/redux/reducers/music";
import { playSpotifyURL } from "../utils/redux/reducers/spotify";
import { post } from "../utils/request";
import { convertTime } from "../utils/time";
import MusicModal from "./MusicModal";


export default function MusicPlayer(props) {

    const data = useSelector(state => state.music.data)
    const dispatch = useDispatch()


    const [modal, setModal] = useState({
        show: false
    })
    const [status, setStatus] = useState({
        show: false,
        showSpinner: true,
        msg: ""
    })

    useEffect(() => {
        if (!status.showSpinner && status.show) {
            setTimeout(() => setStatus({ ...status, show: false }), 5000)
        }
    }, [status])


    function togglePlaybackFunc() {
        dispatch(togglePlayback())
    }


    function skipTrack() {
        dispatch(skip())
    }

    function stopPlayback() {
        dispatch(stop())
    }

    function play(url) {
        setStatus({ show: true, showSpinner: true, msg: "Processing" })
        dispatch(playSpotifyURL(url)).then((res) => {

            setStatus({ show: true, showSpinner: false, msg: res.payload.data.msg })

        })
        setModal({ show: false })
    }


    return (
        <div>
            <MusicModal show={modal.show} handleClose={() => setModal({ show: false })} handleSend={play}></MusicModal>


            <div className="d-flex flex-column flex-md-row align-items-center bg-dark" style={{ borderRadius: 20 }}>
                <div style={{ width: "auto", height: "auto" }} className="m-4">
                    <Image style={{ aspectRatio: "16/9", objectFit: "cover", width: "100%", height: "100%", maxHeight: 260, boxShadow: "4px 4px 20px 3px #00000096", borderRadius: 20 }} src={data.currentTrack ? data.currentTrack.data.thumbnail : '/logo512.png'}></Image>
                </div>
                <div className="d-flex flex-column p-4 pt-1 pt-md-4 w-100" style={{ maxWidth: 550 }}>
                    <div className="mb-4">
                        {!data.currentTrack &&
                            <div className="text-white fw-bold fs-4">No music playing!</div>
                        }
                        <div className="text-white fw-bold fs-4">{data.currentTrack ? data.currentTrack.data.title : ''}</div>
                        {data.currentTrack &&<div className="text-white mt-2">Queued by: {data.currentTrack ? data.currentTrack.data.user.username : ''}</div>}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        {data.currentTrack &&
                            <div>
                                <StopCircleFill onClick={stopPlayback} color="white" size={45} className="me-2"></StopCircleFill>
                                {!data.currentTrack.paused &&
                                    <PauseCircleFill onClick={togglePlaybackFunc} color="white" size={60} className="me-2"></PauseCircleFill>
                                }
                                {data.currentTrack.paused &&
                                    <PlayCircleFill onClick={togglePlaybackFunc} color="white" size={60} className="me-2"></PlayCircleFill>
                                }
                                <SkipForwardCircleFill onClick={skipTrack} color="white" size={45}></SkipForwardCircleFill>
                                

                            </div>
                        }
                        <div>
                            <PlusCircleFill color="white" size={60} onClick={() => setModal({ show: true })}></PlusCircleFill>
                        </div>
                    </div>

                    <Collapse in={status.show}>
                        <div>
                            <div className="mt-4 d-flex align-items-center justify-content-end">
                                <div className="text-white fst-italic fs-6">
                                    {status.msg}
                                </div>
                                {status.showSpinner &&
                                    <Spinner className="ms-3" animation="border" size="sm" variant="light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                }
                            </div>
                        </div>
                    </Collapse>


                </div>
            </div>

        </div>
    )
}