
import { getFromStorage, removeFromStorage, saveToStorage } from "./storage"

const API_URL = window.env.API_URL

export async function get(relUrl, auth = false, guildHeader = null) {
    return request("GET", relUrl, null, auth, guildHeader)
}

export async function post(relUrl, data, auth = false, guildHeader = null) {

    return request("POST", relUrl, data, auth, guildHeader)
}

async function request(method, relUrl, data, auth = false, guildHeader = null) {
    let options = {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        },
    }

    if (guildHeader != null) {
        options.headers['guildId'] = guildHeader
    }

    if (data != null) {
        options.body = JSON.stringify(data)
    }

    if (auth) {
        options.headers.Authorization = "Bearer " + getFromStorage("token")
    }

    const response = await fetch(API_URL + relUrl, options)

    if (response.status == 403 && relUrl != "/login/refresh") {
        // Invalid auth
        removeFromStorage("token")
        if (getFromStorage("refreshToken") != null) {
            const refreshResponse = await post("/login/refresh", { refreshToken: getFromStorage("refreshToken") })
            
            if (refreshResponse.status == 200) {
                saveToStorage("token", refreshResponse.data.token)
                saveToStorage("refreshToken", refreshResponse.data.refreshToken)
                return await request(method, relUrl, data, auth, guildHeader)
            }else{
                removeFromStorage("refreshToken")
            }
        }
    }

    if(response.headers.get("Content-Length") != 0){
        return { status: response.status, data: await response.json() }
    }else{
        return { status: response.status, data: null }
    }
    
}