import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { post } from "../../request"
import { subscribe, unsubscribe } from "../../websocket"

const INITIAL_STATE = {
    data:null,
    subscription: null
}


export const shameMember = createAsyncThunk(
    "voice/shameMember",
    async(userId, thunkAPI)=>{
        return await (await post("/voice/shame", {id: userId}, true, thunkAPI.getState().guilds.active.guildId)).data.msg
    }
)


export const registerVoiceState = createAsyncThunk(
    "voice/registerVoiceState",
    async (guildId, thunkAPI) => {
        const subscription = subscribe("/voiceState/"+guildId, (message) => {
            thunkAPI.dispatch(voiceSlice.actions.setVoiceState(JSON.parse(message.body)))
            
        }, "voiceState")


        return thunkAPI.fulfillWithValue("voiceState")
    }
)

export const unregisterVoiceState = createAsyncThunk(
    "voice/unregisterVoiceState",
    async (empty, thunkAPI) => {
        unsubscribe(thunkAPI.getState().voice.subscription)
        thunkAPI.dispatch(voiceSlice.actions.setVoiceState(null))
        thunkAPI.dispatch(voiceSlice.actions.setSubscription(null))
    }
)


const voiceSlice = createSlice({
    name: "voice",
    initialState: INITIAL_STATE,
    reducers: {
        setVoiceState(state, action){
            state.data = action.payload
        },
        setSubscription(state, action){
            state.subscription = action.payload
        }
    },
    extraReducers:(builder) => {
        builder.addCase(registerVoiceState.fulfilled, (state, action) => {
            state.subscription = action.payload;
        })
    }
})



export const { } = voiceSlice.actions;
export const voiceReducer = voiceSlice.reducer;
