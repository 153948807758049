
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Page from './Page';
import Home from './pages/Home';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Stats from './pages/Stats';
import store from './utils/redux/store';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Page><Home></Home></Page>}>
          </Route>
          <Route exact path="/login" element={<Page title="Login"><Login></Login></Page>}>
          </Route>
          <Route exact path="/profile" element={<Page title="Profile"><Profile></Profile></Page>}>
          </Route>
          <Route exact path="/stats" element={<Page><Stats></Stats></Page>}>
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
