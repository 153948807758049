import { Button, Collapse, Tab, Tabs } from "react-bootstrap"
import { useEffect, useState } from "react"
import { Alert, ListGroupItem } from "react-bootstrap"

import moment from "moment";


import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import BarChart from "../components/charts/BarChart"
import { ArrowClockwise } from "react-bootstrap-icons";
import { reloadStats } from "../utils/redux/reducers/stats";
import EventsChart from "../components/charts/EventsChart";
import { convertUTCToLocal, formatDate } from "../utils/timezone";




function getColor(i) {
    let colors = ['#003c8c', '#29498d', '#3d5790', '#4b6594', '#577499', '#60839f', '#6893a8', '#6da2b3', '#70b3c1', '#6dc3d5'];
    return colors[i]
}

function getCumulatedPlaybacksData(events){
    let data = {}

    const days = [...new Array(365)].map((i, idx) => moment().startOf("day").subtract(idx, "days").format("DD.MM.YYYY"))

    events.forEach(event => {
        if(!Object.keys(data).includes(formatDate(convertUTCToLocal(event.createDate)))){
            data[formatDate(convertUTCToLocal(event.createDate))] = []
        }
        data[formatDate(convertUTCToLocal(event.createDate))].push(event)
    });

    let result = [];

    days.forEach(day => {
        let count = 0;

        if(data[day] != undefined){
            count = data[day].length
        }

        result.push({x: day,y:count})
    })



    return result;
}

export default function Stats(props) {
    const [alert, setAlert] = useState({
        show: false,
        variant: "primary",
        message: ""
    })

    const data = useSelector(state => state.stats)
    const dispatch = useDispatch()


    const mostPlayedData = {
        labels: data.playback.mostPlayed ? data.playback.mostPlayed.map(track => track.video.title).slice(0, 10) : [],
        datasets: [
            {
                label: 'Playbacks',
                data: data.playback.mostPlayed ? data.playback.mostPlayed.map(track => track.count).slice(0, 10) : [],
                backgroundColor: data.playback.mostPlayed ? data.playback.mostPlayed.map((track, i) => getColor(i)) : []
            }
        ]
    }

    const playbacksByDateData = data.playback.playbacks?getCumulatedPlaybacksData(data.playback.playbacks):[]

    const playbackByDate = {

        datasets: [
            {
                label: 'Playbacks',
                data: playbacksByDateData,
                backgroundColor: '#003c8c',
                borderColor: '#6dc3d5',
            }
        ]
    }

    const mostSkippedData = {
        labels: data.playback.mostSkipped ? data.playback.mostSkipped.map(track => track.video.title).slice(0, 10) : [],
        datasets: [
            {
                label: 'Playbacks',
                data: data.playback.mostSkipped ? data.playback.mostSkipped.map(track => track.count).slice(0, 10) : [],
                backgroundColor: data.playback.mostSkipped ? data.playback.mostSkipped.map((track, i) => getColor(i)) : []
            }
        ]
    }

    function getEntriesFromDate(date){
        const items = data.playback.playbacks.filter(entry => formatDate(convertUTCToLocal(entry.createDate)) === date)


        return items
    }


    return (
        <div>
            <Button onClick={() => dispatch(reloadStats())} className="mb-3 d-flex align-items-center">Reload <ArrowClockwise size={20} className="ms-2"></ArrowClockwise></Button>

            <Collapse in={alert.show}>
                <div>
                    <Alert variant={alert.variant} onClose={() => setAlert({ show: false, message: "" })} dismissible>
                        {alert.message}
                    </Alert>
                </div>
            </Collapse>

            <Tabs defaultActiveKey="top-tracks" className="mb-3">
                <Tab eventKey="top-tracks" title="Top-Tracks">

                    <BarChart data={mostPlayedData}></BarChart>

                </Tab>
                <Tab eventKey="top-tracks-skipped" title="Top-Tracks Skipped">
                    <BarChart data={mostSkippedData}></BarChart>

                </Tab>
                <Tab eventKey="playback-events" title="Playbacks">
                    <EventsChart getEntriesFromDate={getEntriesFromDate} data={playbackByDate}></EventsChart>
                </Tab>
                <Tab eventKey="general-stats" title="General stats">
                    {data.playback.totalTracksPlayed && <div><b>Total plays:</b> {data.playback.totalTracksPlayed}</div>}
                    {data.playback.totalTracksSkipped && <div><b>Total skips:</b> {data.playback.totalTracksSkipped}</div>}
                </Tab>
            </Tabs>

        </div>
    )
}