import { Button, Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { getSpotifyAuthLink } from "../utils/redux/reducers/spotify"



export default function Profile(props) {
    const state = useSelector(state => state.spotify)

    return(
        <Row>
        <Col xs="8" md="9">
            {!state.isChecked &&
                <Button href={getSpotifyAuthLink()}>Login Spotify</Button>
            }
            {state.isChecked &&
            <div>
                <Button variant="secondary">Logout Spotify ({state.name})</Button>
            </div>
            }
        </Col>
    </Row>
    )


}