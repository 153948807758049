import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';


  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip
  );



export default function BarChart(props){
    return <div>
        <h2>{props.title}</h2>
        <Bar data={props.data} options={{
            
    responsive:true,
    indexAxis: 'y',
    animation: {
        animateScale:true
    },
    scales:{
        x:{ticks:{precision: 0}},
        y:{ticks:{
            align:'start'
        }}
    },
    


  }}></Bar>
    </div>
}